// Core
import { $http } from "api";

class AdminService {
  static async getCompanyUsers() {
    return $http.get("/admin/users");
  }

  static async getUserDetails(userId) {
    return $http.get(`/admin/users/${userId}/details`);
  }

  static async updateUserDetails(userId, user) {
    return $http.post(`/admin/users/${userId}/details`, { ...user });
  }

  static async getUserChannels(userId) {
    return $http.get(`/admin/users/${userId}/user-channels`);
  }

  static async getChannels(userId) {
    return $http.get(`/admin/users/${userId}/channels`);
  }

  static async getUserNotifications(userId) {
    return $http.get(`/admin/users/${userId}/notification`);
  }

  static async updateUserNotifications(userId, notifications) {
    return $http.post(`/admin/users/${userId}/notification`, {
      ...notifications,
    });
  }

  static async addUserToChannel(userId, channelId) {
    return $http.post(`/admin/users/${userId}/add-channel`, {
      ...channelId,
    });
  }

  static async removeUserFromChannel(userId, channelId) {
    return $http.post(`/admin/users/${userId}/remove-channel`, {
      ...channelId,
    });
  }

  static async createUser(user) {
    return $http.post(`/admin/create-user`, {
      ...user,
    });
  }

  static async disableUser(userId) {
    return $http.get(`/admin/users/${userId}/disable-user`);
  }

  static async getCompanyChannels() {
    return $http.get("/admin/channels");
  }

  static async getChannelDetails(channelId) {
    return $http.get(`/admin/channels/${channelId}/details`);
  }

  static async updateChannelDetails(channelId, channel) {
    return $http.post(`/admin/channels/${channelId}/details`, { ...channel });
  }
}

export default AdminService;
