// Types
import * as types from "redux/actiontypes/chat-types";

const initialState = {
  channelId: localStorage.getItem("channelId") || null,
  store: {},
  channelMessages: [],
  participants: [],
  unreadMessageCount: {},
  newLike: null,
  newMessage: null,
  reply: null,
  socket: null,
  searchByChannel: "",
  showChannelsSidebar: localStorage.getItem("channelId") ? false : true,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CHANNEL_ID:
      return {
        ...state,
        channelId: action.payload,
      };
    case types.SET_CHANNEL_MESSAGES:
      return {
        ...state,
        channelMessages: action.payload.reverse(),
      };
    case types.SET_PARTICIPANTS:
      return {
        ...state,
        participants: action.payload,
      };
    case types.SET_SOCKET:
      return {
        ...state,
        socket: action.payload,
      };
    case types.SET_STORE:
      return {
        ...state,
        store: action.payload,
      };
    case types.SET_NEW_LIKE:
      return {
        ...state,
        newLike: action.payload,
      };
    case types.SET_NEW_MESSAGE:
      return {
        ...state,
        newMessage: action.payload,
      };
    case types.ADD_NEW_MESSAGE:
      return {
        ...state,
        channelMessages: [...state.channelMessages, action.payload],
      };
    case types.UPDATE_MESSAGE: {
      const updatedMessages = state.channelMessages.map((msg) =>
        msg.id === action.payload.id ? { ...action.payload } : msg
      );
      return {
        ...state,
        channelMessages: updatedMessages,
      };
    }
    case types.SET_UNREAD_COUNT:
      return {
        ...state,
        unreadMessageCount: action.payload,
      };
    case types.UPDATE_UNREAD_TOTAL_COUNT:
      return {
        ...state,
        unreadMessageCount: {
          ...state.unreadMessageCount,
          [action.payload]: {
            ...state.unreadMessageCount[action.payload],
            total: state.unreadMessageCount[action.payload].total + 1,
          },
        },
      };
    case types.UPDATE_UNREAD_ALERT_COUNT:
      return {
        ...state,
        unreadMessageCount: {
          ...state.unreadMessageCount,
          [action.payload]: {
            ...state.unreadMessageCount[action.payload],
            alerts: state.unreadMessageCount[action.payload].total + 1,
          },
        },
      };
    case types.CLEAR_UNREAD_COUNT:
      return {
        ...state,
        unreadMessageCount: {
          ...state.unreadMessageCount,
          [action.payload]: {
            total: 0,
            alerts: 0,
          },
        },
      };
    case types.SET_REPLY_TO:
      return {
        ...state,
        reply: action.payload,
      };
    case types.SEARCH_BY_CHANNEl:
      return {
        ...state,
        searchByChannel: action.payload,
      };
    case types.SHOW_CHANNELS_SIDEBAR:
      return {
        ...state,
        showChannelsSidebar: action.payload,
      };
    case types.CLEAR_CHANNEL:
      return {
        ...state,
        channelId: null,
        store: {},
        channelMessages: [],
        reply: null,
        showChannelsSidebar: true,
      };
    default:
      return state;
  }
};

export { chatReducer };
