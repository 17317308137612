// Core
import React from "react";
import clsx from "clsx";

// Components
import { Typography, Button } from "@material-ui/core";

// Icons
import { Done } from "@material-ui/icons";

// Styles
import { useStyles } from "./channel-card.styles";

function ChannelCardTitle({ title }) {
  const classes = useStyles();
  return (
    <Typography className={classes.title} variant="subtitle1">
      {title}
    </Typography>
  );
}

function ChannelCardAddress({ addressLine, state, city, description }) {
  const classes = useStyles();
  if(!addressLine) {
    return (
    <Typography className={classes.address}>
      <span className={classes.description}>{description}</span>
    </Typography>
    )
  }
  return (
    <Typography className={classes.address}>
      <span className={classes.addressLine}>{addressLine},</span>
      <span className={classes.addressLine}>{city},</span>
      <span className={classes.addressLine}>{state}</span>
    </Typography>
  );
}

function ChannelCardButton({
  onClick = () => {},
  isSubscribed = false,
  disabled = false,
  children,
}) {
  const classes = useStyles();
  return (
    <>
      {isSubscribed ? (
        <Button
          className={clsx(classes.button, classes.buttonSubscribed)}
          size="small"
          variant="outlined"
          startIcon={
            <Done className={classes.buttonSubscribedIcon} fontSize="small" />
          }
          disabled
          fullWidth
        >
          Assigned
        </Button>
      ) : (
        <Button
          className={classes.button}
          onClick={onClick}
          size="small"
          variant="outlined"
          color="primary"
          fullWidth
          disabled={disabled}
        >
          {children}
        </Button>
      )}
    </>
  );
}

function ChannelUsersCount({ count }) {
  const classes = useStyles();

  const getCount = (count) => {
    if (count === 0) return "No users";
    return count === 1 ? `${count} user` : `${count} users`;
  };

  return (
    <Typography className={classes.userCount}>{getCount(count)}</Typography>
  );
}

function ChannelCard({ children }) {
  const classes = useStyles();
  return <section className={classes.card}>{children}</section>;
}

export {
  ChannelCard,
  ChannelCardTitle,
  ChannelCardAddress,
  ChannelCardButton,
  ChannelUsersCount,
};
