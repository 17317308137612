// Service
import { ApiError } from "api";
import { AdminService } from "services";

// Types
import * as types from "redux/actiontypes/admin-types";

const getCompanyUsers = () => async () => {
  try {
    const { data } = await AdminService.getCompanyUsers();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getUserDetails = (userId) => async () => {
  try {
    const { data } = await AdminService.getUserDetails(userId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateUserDetails = (userId, user) => async () => {
  try {
    const { data } = await AdminService.updateUserDetails(userId, user);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getUserChannels = (userId) => async () => {
  try {
    const { data } = await AdminService.getUserChannels(userId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getChannels = (userId) => async () => {
  try {
    const { data } = await AdminService.getChannels(userId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getUserNotifications = (userId) => async () => {
  try {
    const { data } = await AdminService.getUserNotifications(userId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateUserNotifications = (userId, notifications) => async () => {
  try {
    const { data } = await AdminService.updateUserNotifications(
      userId,
      notifications
    );
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const addUserToChannel = (userId, channelId) => async () => {
  try {
    const { data } = await AdminService.addUserToChannel(userId, channelId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const removeUserFromChannel = (userId, channelId) => async () => {
  try {
    const { data } = await AdminService.removeUserFromChannel(
      userId,
      channelId
    );
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const createUser = (user) => async () => {
  try {
    const { data } = await AdminService.createUser(user);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const disableUser = (userId) => async () => {
  try {
    const { data } = await AdminService.disableUser(userId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getCompanyChannels = () => async () => {
  try {
    const { data } = await AdminService.getCompanyChannels();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getChannelDetails = (channelId) => async () => {
  try {
    const { data } = await AdminService.getChannelDetails(channelId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateChannelDetails = (channelId, channel) => async () => {
  try {
    const { data } = await AdminService.updateChannelDetails(
      channelId,
      channel
    );
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const searchByCompanyUsers = (user) => ({
  type: types.SEARCH_BY_COMPANY_USERS,
  payload: user,
});

const searchByUserChannels = (channel) => ({
  type: types.SEARCH_BY_USER_CHANNELS,
  payload: channel,
});

const searchByChannels = (channel) => ({
  type: types.SEARCH_BY_CHANNELS,
  payload: channel,
});

const setActiveUserDetailsTab = (activeTab) => ({
  type: types.SET_ACTIVE_USER_DETAILS_TAB,
  payload: activeTab,
});

const setActiveChannelDetailsTab = (activeTab) => ({
  type: types.SET_ACTIVE_CHANNEL_DETAILS_TAB,
  payload: activeTab,
});

const searchUserInChannel = (user) => ({
  type: types.SEARCH_USER_IN_CHANNEL,
  payload: user,
});

export {
  getCompanyUsers,
  getUserDetails,
  updateUserDetails,
  getUserChannels,
  getChannels,
  getUserNotifications,
  updateUserNotifications,
  addUserToChannel,
  removeUserFromChannel,
  createUser,
  disableUser,
  getCompanyChannels,
  getChannelDetails,
  updateChannelDetails,
  searchByCompanyUsers,
  searchByUserChannels,
  searchByChannels,
  setActiveUserDetailsTab,
  setActiveChannelDetailsTab,
  searchUserInChannel,
};
