// Core
import React from "react";

// Components
import { FormControlLabel, Switch, Typography } from "@material-ui/core";

// Styles
import { useStyles } from "./switch.styles";

const MuiSwitch = ({ alert, disabled, onChange }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.formControl}
      disabled={disabled}
      control={
        <Switch
          checked={alert.enabled}
          onChange={(e) => onChange(e, alert)}
          name={alert.name}
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
        />
      }
      label={
        <Typography className={classes.label} variant="caption">
          {alert.pretty_name}
        </Typography>
      }
    />
  );
};

export default MuiSwitch;
