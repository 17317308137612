// Core
import React, { useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import clsx from "clsx";

// Actions
import { showSettingsSidebar } from "redux/actions/settings-actions";
import {
  setActiveChannelDetailsTab,
  getChannelDetails,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import { MuiBackButton, MuiTabs } from "components/common";
import {
  Paper,
  AdminChannelInfo,
  AdminChannelUsers,
  AdminTitle,
} from "components/settings";
import { Typography, Breadcrumbs, Link } from "@material-ui/core";

// Hooks
import { useBreakpoints } from "hooks";

// Icons
import { NavigateNext } from "@material-ui/icons";

// Styles
import { useStyles } from "./channel-details.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const AdminChannelDetails = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isMobile, isDesktop } = useBreakpoints();
  const activeTab = useSelector((state) => state.admin.activeChannelDetailsTab);
  const [channelDetails, setChannelDetails] = useState(null);

  useQuery(
    ["admin_channel_details", id],
    () => dispatch(getChannelDetails(id)),
    {
      onSuccess: (data) => {
        setChannelDetails(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  const handleBackButtonClick = () => dispatch(showSettingsSidebar(false));

  return (
    <Catcher>
      {isMobile && (
        <div className={globalClasses.container}>
          <MuiBackButton
            to="/settings/admin/channels"
            onClick={handleBackButtonClick}
          />
          {channelDetails && (
            <AdminTitle
              title={`${channelDetails.name}`}
            />
          )}
        </div>
      )}

      <div className={clsx(isMobile && globalClasses.container)}>
        <Paper>
          {isDesktop && (
            <div className={classes.titleRow}>
              <Breadcrumbs
                aria-label="breadcrumb"
                className={classes.breadcrumb}
                separator={<NavigateNext fontSize="small" />}
              >
                <Link
                  className={classes.breadcrumbItemRoot}
                  component={RouterLink}
                  to="/settings/admin/channels"
                >
                  Channels
                </Link>
                <Typography className={classes.breadcrumbItem}>
                  {channelDetails && (
                    <>{`${channelDetails.name}`}</>
                  )}
                </Typography>
              </Breadcrumbs>
            </div>
          )}

          <MuiTabs
            labels={["Channel information", "Assigned users"]}
            onChange={setActiveChannelDetailsTab}
            activeTab={activeTab}
          >
            <AdminChannelInfo />
            <AdminChannelUsers />
          </MuiTabs>
        </Paper>
      </div>
    </Catcher>
  );
};

export default AdminChannelDetails;
