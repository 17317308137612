// Core
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import clsx from "clsx";

// Actions
import { showSettingsSidebar } from "redux/actions/settings-actions";

// Components
import { MuiBarSpacer } from "components/common";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Drawer,
} from "@material-ui/core";

// Icons
import { ReactComponent as ProfileIcon } from "theme/icons/my-profile.svg";
import { ReactComponent as SecurityIcon } from "theme/icons/security.svg";
import { ReactComponent as NotificationsIcon } from "theme/icons/admin-notification.svg";
import { ReactComponent as AdminUsersIcon } from "theme/icons/admin-users.svg";
import { ReactComponent as MealIcon } from "theme/icons/meal.svg";

// Styles
import { useStyles } from "./sidebar.styles";

const userLinks = [
  {
    to: "/settings/user-info",
    text: "My profile",
    icon: <ProfileIcon className="strokeIcon" />,
  },
  {
    to: "/settings/security",
    text: "Security",
    icon: <SecurityIcon className="fillIcon" />,
  },
  {
    to: "/settings/notifications",
    text: "Notifications",
    icon: <NotificationsIcon className="fillIcon" />,
  },
];

const adminLinks = [
  {
    to: "/settings/admin/users",
    text: "Users",
    icon: <AdminUsersIcon className="strokeIcon" />,
  },
  {
    to: "/settings/admin/channels",
    text: "Channels",
    icon: <MealIcon className="fillIcon" />,
  },
];

const SettingsSidebar = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.auth.user?.is_admin);
  const showSidebar = useSelector(
    (state) => state.settings.showSettingsSidebar
  );

  const handleActiveNav = (path) => {
    return location.pathname.includes(path);
  };

  const handleLinkClick = (to) => {
    dispatch(showSettingsSidebar(false));
    history.push(to);
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      className={clsx(classes.drawer, showSidebar ? "" : classes.hideDrawer)}
    >
      <div className={classes.wrapper}>
        <MuiBarSpacer />
        <Typography
          variant="h5"
          className={clsx(classes.container, classes.title)}
        >
          Settings
        </Typography>

        <Typography
          variant="body1"
          className={clsx(classes.container, classes.subTitle)}
        >
          Personal settings
        </Typography>
        <List
          className={clsx(classes.container, classes.list)}
          component="nav"
          aria-label="user settings"
        >
          {userLinks.map((link) => (
            <Link
              key={link.to}
              className={classes.link}
              onClick={() => handleLinkClick(link.to)}
              color="inherit"
              underline="none"
            >
              <ListItem
                className={classes.linkItem}
                button
                disableRipple
                selected={handleActiveNav(link.to)}
              >
                <ListItemIcon className={classes.listIcon}>
                  {link.icon}
                </ListItemIcon>
                <ListItemText primary={link.text} />
              </ListItem>
            </Link>
          ))}
        </List>

        {isAdmin && (
          <>
            <Typography
              variant="body1"
              className={clsx(classes.container, classes.subTitle)}
            >
              Admin settings
            </Typography>
            <List
              className={clsx(classes.container, classes.list)}
              component="nav"
              aria-label="admin settings"
            >
              {adminLinks.map((link) => (
                <Link
                  key={link.to}
                  className={classes.link}
                  onClick={() => handleLinkClick(link.to)}
                  color="inherit"
                  underline="none"
                >
                  <ListItem
                    className={classes.linkItem}
                    button
                    disableRipple
                    selected={handleActiveNav(link.to)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      {link.icon}
                    </ListItemIcon>
                    <ListItemText primary={link.text} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </>
        )}
      </div>
    </Drawer>
  );
};

export default SettingsSidebar;
