// Core
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Components
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  adminTitle: {
    marginRight: theme.spacing(2),
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.main,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  userTitle: {
    marginBottom: theme.spacing(2),
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.main,
  },
}));

const AdminTitle = ({ title }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.adminTitle} component="h1" variant="h5">
      {title}
    </Typography>
  );
};

const UserTitle = ({ title }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.userTitle} component="h1" variant="h5">
      {title}
    </Typography>
  );
};

export { AdminTitle, UserTitle };
