// Core
import React, { useEffect } from "react";
import { useScrollToBottom } from "react-scroll-to-bottom";
import { useSelector } from "react-redux";

// Components
import { List, Typography } from "@material-ui/core";
import { ChatMessage, ChatAlert, ChatCommunicationAlert } from "components";

// Styles
import { useStyles } from "./chat-messages.styles";

const ChatMessages = React.memo(({ messages, socket }) => {
  const classes = useStyles();
  const scrollToBottom = useScrollToBottom();
  const userId = useSelector((state) => state.auth.user.id);

  useEffect(() => {
    if (messages[messages.length - 1]?.user?.id === userId)
      scrollToBottom({ behavior: "smooth" });
  }, [messages, userId, scrollToBottom]);

  if (messages.length === 0) {
    return (
      <Typography
        variant="caption"
        color="textPrimary"
        className={classes.empty}
      ></Typography>
    );
  }

  return (
    <List className={classes.root}>
      {messages.map((message) => (
        <div key={message.id}>
          {message.type === "message" && (
            <ChatMessage message={message} socket={socket} />
          )}
          {message.type === "alert" && (
            <ChatAlert message={message} socket={socket} />
          )}
          {message.type === "communication" && (
            <ChatCommunicationAlert message={message} socket={socket} />
          )}
        </div>
      ))}
    </List>
  );
});

export default ChatMessages;
