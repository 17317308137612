// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  messagesWrapper: {
    width: "100%",
    minHeight: 150,
    [theme.breakpoints.down("sm")]: {
      minHeight: 350,
    },
  },
  chatInfo: {
    position: "fixed",
    top: 56,
    left: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: 65,
    zIndex: 102,
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.grey.border}`,
  },
  back: {
    height: "100%",
  },
  backIcon: {
    marginRight: theme.spacing(1),
  },
  counter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 21,
    height: 21,
    padding: "0 5px",
    borderRadius: "100px",
    whiteSpace: "nowrap",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.red.alert,
  },
  store: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: theme.spacing(3),
  },
  chatBadgesDesktop: {
    position: "fixed",
    top: 80,
    right: 40,
    zIndex: 99,
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.text.main,
    letterSpacing: "0.25px",
  },
  address: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.darkGrey,
  },
  scrollWrapper: {
    paddingBottom: 120,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 65,
    },
  },
}));

export { useStyles };
