// Core
import { $http } from "api";

class ChatService {
  static async getChannelMessages(channelId) {
    return $http.post(`/chat/${channelId}/messages`);
  }

  static async getChatBadges(channelId) {
    return $http.get(`/chat/${channelId}/badges`);
  }

  static async getUserChannels() {
    return $http.get("/chat/channels");
  }

  static async createMessage(message) {
    return $http.post("/chat/messages/new", message);
  }

  static async createLike(like) {
    return $http.post("/chat/messages/like", like);
  }

  static async updateLastTimestamp(channelId) {
    return $http.post("/chat/channels/latest", { channelId });
  }
}

export default ChatService;
