// Core
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import ScrollToBottom from "react-scroll-to-bottom";
import clsx from "clsx";

// Actions
import {
  getChannelMessages,
  setChannelMessages,
  setParticipants,
  setStore,
  clearChannel,
  setChannelId,
  showChannelsSidebar,
} from "redux/actions/chat-actions";

// Hooks
import { useBreakpoints } from "hooks";

// Components
import { MuiLoader } from "components/common";
import { ChatMessages, ChatInput, ChatBadges } from "components";
import { Alert } from "@material-ui/lab";
import { Typography, Box } from "@material-ui/core";

// Icons
import { ReactComponent as BackIcon } from "theme/icons/back.svg";

// Styles
import { useStyles } from "./chat.styles";

// Tools
import { isEmpty } from "utils/helpers";

const Chat = ({ socket }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { channelId, channelMessages, store, unreadMessageCount } = useSelector(
    (state) => state.chat
  );
  const sidebarIsOpen = useSelector((state) => state.chat.showChannelsSidebar);
  const { isMobile, isDesktop } = useBreakpoints();
  const [totalCount, setTotalCount] = useState(null);

  const { error, isLoading, isError, isSuccess, mutate } = useMutation(
    (id) => dispatch(getChannelMessages(id)),
    {
      onSuccess: (data) => {
        dispatch(setChannelMessages(data?.messages || []));
        dispatch(setParticipants(data?.participants || []));
        dispatch(setStore(data?.store || {}));
      },
    }
  );

  useEffect(() => {
    if (!isEmpty(unreadMessageCount)) {
      let alerts = 0;
      let totals = 0;
      Object.values(unreadMessageCount).forEach((obj) => {
        alerts += obj.alerts;
        totals += obj.total;
      });
      const totalCount = alerts + totals;
      setTotalCount(totalCount);
    }
  }, [unreadMessageCount, channelId]);

  useEffect(() => {
    if (channelId && !id) {
      mutate(channelId);
    }
  }, [channelId, id, mutate]);

  useEffect(() => {
    if (id) {
      mutate(id);
      dispatch(setChannelId(id));
      dispatch(showChannelsSidebar(false));
      localStorage.setItem("channelId", id);
    }
  }, [id, dispatch, mutate]);

  const handleBackClick = () => {
    dispatch(clearChannel());
    setTotalCount(null);
    history.push("/chat");
  };

  return (
    <>
      {isMobile && !sidebarIsOpen && (
        <div className={clsx(classes.chatInfo, classes.container)}>
          <div
            className={clsx(classes.row, classes.back)}
            onClick={handleBackClick}
          >
            <BackIcon className={classes.backIcon} />
            {totalCount > 0 && (
              <span className={classes.counter}>
                {totalCount > 99 ? "99 +" : totalCount}
              </span>
            )}
          </div>
          {!isEmpty(store) && (
            <section className={classes.store}>
              <Box className={classes.storeDescription}>
                <Typography className={classes.title} variant="subtitle2">
                  #{store.store_name}
                </Typography>
                <span className={classes.address}>
                  {store.city}, {store.state}
                </span>
              </Box>
              {channelId && (
                <Box className={classes.storeBadges}>
                  <ChatBadges channelId={channelId} />
                </Box>
              )}
            </section>
          )}
        </div>
      )}

      <div className={classes.messagesWrapper}>
        {isLoading && <MuiLoader fullpage />}
        {isError && <Alert severity="error">{error.message}</Alert>}
        {isSuccess && (
          <ScrollToBottom
            className={clsx(classes.scrollWrapper, "viewportHeight")}
            initialScrollBehavior="auto"
          >
            <ChatMessages messages={channelMessages} socket={socket} />
          </ScrollToBottom>
        )}
      </div>

      {channelId && <ChatInput socket={socket} />}
      {channelId && isDesktop && (
        <Box className={classes.chatBadgesDesktop}>
          <ChatBadges channelId={channelId} />
        </Box>
      )}
    </>
  );
};

export default Chat;
